import React from 'react'
import { graphql } from 'gatsby'

import { getSiteData } from '@/utils/siteData'
import { PageBackgroundColors } from '@/constants/enums'
import Layout from '@/components/layout'
import Sections from '@/components/sections'
import SEO from '@/components/seo'

const DynamicPage = ({ data, pageContext, location }) => {
  const {
    posts,
    insights,
    releaseNotes,
    site,
    form,
    downloads,
    downloadCategories,
    trainings,
    events,
  } = data
  const { sections, title, seo, backgroundColor } = data.page || {}
  const global = {
    posts: posts ? posts.nodes : [],
    insights: insights ? insights.nodes : [],
    releaseNotes: releaseNotes ? releaseNotes.nodes : [],
    downloads: downloads ? downloads.nodes : [],
    downloadCategories: downloadCategories ? downloadCategories.nodes : [],
    trainings: trainings ? trainings.nodes : [],
    events: events ? events.nodes : [],
    form: form && form.nodes && form.nodes.length ? form.nodes[0] : {},
  }

  return (
    <>
      <SEO
        title={title}
        seo={seo}
        site={site.nodes[0]}
        pageContext={pageContext}
      />
      <Layout
        global={getSiteData(data)}
        pageContext={pageContext}
        backgroundColor={backgroundColor || PageBackgroundColors.gray}
        location={location}
      >
        <Sections as="main" sections={sections} global={global} />
      </Layout>
    </>
  )
}

export default DynamicPage

export const query = graphql`
  query DynamicPageQuery(
    $id: String!
    $locale: String!
    $formatString: String = "DD.MM.YYYY"
  ) {
    locales: allLocale(filter: { language: { eq: $locale } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    site: allStrapiSite(filter: { locale: { eq: $locale } }) {
      nodes {
        ...Site
      }
    }
    form: allStrapiForm(filter: { locale: { eq: $locale } }) {
      nodes {
        ...SharedForm
      }
    }
    header: allStrapiHeader(filter: { locale: { eq: $locale } }) {
      nodes {
        ...Header
      }
    }
    footer: allStrapiFooter(filter: { locale: { eq: $locale } }) {
      nodes {
        ...Footer
      }
    }
    page: strapiPage(id: { eq: $id }) {
      slug
      title
      backgroundColor
      seo {
        ...SharedSeo
      }
      sections {
        ... on STRAPI__COMPONENT_SECTIONS_ACCOUNT_REGISTRATION {
          ...SectionsAccountRegistration
        }
        ... on STRAPI__COMPONENT_SECTIONS_ANCHOR_NAVIGATION {
          ...SectionsAnchorNavigation
        }
        ... on STRAPI__COMPONENT_SECTIONS_BADGE_DIVIDER {
          ...SectionsBadgeDivider
        }
        ... on STRAPI__COMPONENT_SECTIONS_COLUMNS {
          ...SectionsColumns
        }
        ... on STRAPI__COMPONENT_SECTIONS_COLUMNS_HEADING_IMAGE {
          ...SectionsColumnsHeadingImage
        }
        ... on STRAPI__COMPONENT_SECTIONS_COLUMNS_HEADING_TEXT {
          ...SectionsColumnsHeadingText
        }
        ... on STRAPI__COMPONENT_SECTIONS_COLUMNS_HEADING_VIDEO {
          ...SectionsColumnsHeadingVideo
        }
        ... on STRAPI__COMPONENT_SECTIONS_COLUMNS_HIGHLIGHT_IMAGE {
          ...SectionsColumnsHighlightImage
        }
        ... on STRAPI__COMPONENT_SECTIONS_COLUMNS_HIGHLIGHT_TEXT {
          ...SectionsColumnsHighlightText
        }
        ... on STRAPI__COMPONENT_SECTIONS_COLUMNS_HIGHLIGHT_VIDEO {
          ...SectionsColumnsHighlightVideo
        }
        ... on STRAPI__COMPONENT_SECTIONS_FEATURE_LIST {
          ...SectionsFeatureList
        }
        ... on STRAPI__COMPONENT_SECTIONS_FEATURE_DESCRIPTION {
          ...SectionsFeatureDescription
        }
        ... on STRAPI__COMPONENT_SECTIONS_HEADING_IMAGE {
          ...SectionsHeadingImage
        }
        ... on STRAPI__COMPONENT_SECTIONS_HEADING_SECTION {
          ...SectionsHeadingSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_HERO_COLLECTION {
          ...SectionsHeroCollection
        }
        ... on STRAPI__COMPONENT_SECTIONS_HERO_COMPACT {
          ...SectionsHeroCompact
        }
        ... on STRAPI__COMPONENT_SECTIONS_HERO_SECTION {
          ...SectionsHeroSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_HIGHLIGHT_FIGURE {
          ...SectionsHighlightFigure
        }
        ... on STRAPI__COMPONENT_SECTIONS_HIGHLIGHT_LOGO {
          ...SectionsHighlightLogo
        }
        ... on STRAPI__COMPONENT_SECTIONS_HIGHLIGHT_PLATFORM {
          ...SectionsHighlightPlatform
        }
        ... on STRAPI__COMPONENT_SECTIONS_HIGHLIGHT_STATS {
          ...SectionsHighlightStats
        }
        ... on STRAPI__COMPONENT_SECTIONS_HIGHLIGHT_TEXT {
          ...SectionsHighlightText
        }
        ... on STRAPI__COMPONENT_SECTIONS_LATEST_ARTICLES {
          ...SectionsLatestArticles
        }
        ... on STRAPI__COMPONENT_SECTIONS_LATEST_INSIGHTS {
          ...SectionsLatestInsights
        }
        ... on STRAPI__COMPONENT_SECTIONS_LATEST_POSTS {
          ...SectionsLatestPosts
        }
        ... on STRAPI__COMPONENT_SECTIONS_LATEST_POSTS_CAROUSEL {
          ...SectionsLatestPostsCarousel
        }
        ... on STRAPI__COMPONENT_SECTIONS_LATEST_RELEASE_NOTES {
          ...SectionsLatestReleaseNotes
        }
        ... on STRAPI__COMPONENT_SECTIONS_PARTNER_CLOUD {
          ...SectionsPartnerCloud
        }
        ... on STRAPI__COMPONENT_SECTIONS_PARTNER_COLLECTION {
          ...SectionsPartnerCollection
        }
        ... on STRAPI__COMPONENT_SECTIONS_TEASER_COLLECTION {
          ...SectionsTeaserCollection
        }
        ... on STRAPI__COMPONENT_SECTIONS_TEASER_IMAGE {
          ...SectionsTeaserImage
        }
        ... on STRAPI__COMPONENT_SECTIONS_TEASER_LOGO {
          ...SectionsTeaserLogo
        }
        ... on STRAPI__COMPONENT_SECTIONS_TESTIMONIAL_COLLECTION {
          ...SectionsTestimonialCollection
        }
        ... on STRAPI__COMPONENT_SECTIONS_TESTIMONIAL_SECTION {
          ...SectionsTestimonialSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_TEXT_SECTION {
          ...SectionsTextSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_TOPIC_COLLECTION {
          ...SectionsTopicCollection
        }
        ... on STRAPI__COMPONENT_SECTIONS_DOWNLOADS {
          ...SectionsDownloads
        }
        ... on STRAPI__COMPONENT_SECTIONS_TRAINING_COLLECTION {
          ...SectionsTrainingCollection
        }
        ... on STRAPI__COMPONENT_SECTIONS_NEXT_EVENTS {
          ...SectionsNextEvents
        }
        ... on STRAPI__COMPONENT_SECTIONS_CONTACT {
          ...SectionsContact
        }
        ... on STRAPI__COMPONENT_SECTIONS_NEWSLETTER {
          ...SectionsNewsletter
        }
        ... on STRAPI__COMPONENT_SECTIONS_CUSTOM_POST_SELECTION {
          ...SectionsCustomPostSelection
        }
        ... on STRAPI__COMPONENT_SECTIONS_BREAKING_NEWS {
          ...SectionsBreakingNews
        }
      }
    }
    posts: allStrapiPost(
      filter: { locale: { eq: $locale } }
      sort: { fields: datetime, order: DESC }
    ) {
      nodes {
        route
        slug
        title
        teaserHeadline
        excerpt
        datetime(formatString: $formatString, locale: $locale)
        releasedAt: datetime
        category
        cover {
          mime
          url
          alt: alternativeText
          caption
          width
          height
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
    insights: allStrapiInsight(
      filter: { locale: { eq: $locale } }
      sort: { fields: datetime, order: DESC }
    ) {
      nodes {
        route
        title
        teaserHeadline
        datetime(formatString: $formatString, locale: $locale)
        releasedAt: datetime
        platforms {
          platformId
        }
      }
    }
    releaseNotes: allStrapiReleaseNote(
      filter: { locale: { eq: $locale } }
      sort: { fields: datetime, order: DESC }
    ) {
      nodes {
        route
        title
        teaserHeadline
        subline
        datetime(formatString: $formatString, locale: $locale)
        releasedAt: datetime
        platforms {
          platformId
        }
      }
    }
    downloads: allStrapiDownload(filter: { locale: { eq: $locale } }) {
      nodes {
        ...Download
      }
    }
    downloadCategories: allStrapiDownloadCategory(
      filter: { locale: { eq: $locale } }
    ) {
      nodes {
        ...DownloadCategory
      }
    }
    trainings: allStrapiEvent(
      filter: {
        locale: { eq: $locale }
        type: { eq: "training" }
        isFuture: { eq: true }
      }
    ) {
      nodes {
        ranking
        title
        description
        teaserTitle
        teaserDescription
        image {
          ...SharedImage
        }
        route
        isFuture
        endDate
      }
    }
    events: allStrapiEvent(
      filter: {
        locale: { eq: $locale }
        type: { eq: "event" }
        isFuture: { eq: true }
      }
      sort: { fields: startDate, order: ASC }
    ) {
      nodes {
        title
        teaserTitle
        image {
          ...SharedImage
        }
        route
        isFuture
        startDate
        endDate
        place
        logo {
          ...SharedImage
        }
      }
    }
  }
`
